nav.navbar,
.navbar-default {
  background-color: #2892d7 !important;
}

.navbar-default {
  background-image: none;
  border: none;
  border-radius: 0;
}

.navbar-default .navbar-nav > li > a {
  color: white;
}

.navbar-default .navbar-brand {
  color: white;
}

.navbar-default .navbar-toggle {
  border-color: gold;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  color: white;
  background-color: transparent;
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: white;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a:focus {
  color: white;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a:hover {
  color: gold;
  background-color: transparent;
}
.navbar-default .navbar-link {
  color: black;
  border: none;
  border-radius: 10px;
  background-color: #efb332;
  font-size: 14px;
  padding: 5px;
}
