.jumbotron {
  margin-bottom: 4em;
}
.person-wrapper {
  margin-bottom: 2em;
  border: 6px;
  border-color: #000000;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 5px;
}

cardheader {
  background-color: #ff0000;
  font-size: 48px;
}

.data-wrapper {
  margin-bottom: 2em;
  border: 2px;
  border-radius: 10px;
  background-color: #84aff4;
  padding: 10px;
}

.profile-pic {
  width: 50%;
}

.btn-primary {
  background-image: none;
  border: none;
  border-radius: 0;
  background-color: #2892d7;
}

.btn-primary:focus {
  outline: none;
}

.btn-oranje {
  border: none;
  border-radius: 10px;
  background-color: #efb332;
  font-size: 14px;
  padding: 10px;
  margin: 5px;
}
.btn-oranje:focus {
  outline: none;
}
.btn-oranje:active,
.btn-groen:active,
.btn-blue:active {
  vertical-align: top;
  padding: 8px 13px 6px;
}
.btn-groen {
  border: none;
  border-radius: 10px;
  background-color: #b3ef32;
  font-size: 14px;
  padding: 10px;
  margin: 5px;
}
.btn-groen:focus {
  outline: none;
}

.btn-blue {
  border: none;
  border-radius: 10px;
  background-color: #438bdd;
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
  margin: 5px;
}
.btn-blue:focus {
  outline: none;
}

.btn-unselected {
  border: none;
  border-radius: 10px;
  background-color: #535252;
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
  margin: 5px;
}
.btn-blue:focus {
  outline: none;
}

h3 {
  margin-bottom: 1em;
}
